
import { useState } from 'react'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel, } from '@headlessui/react'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Container } from './styled'
import { useDispatch } from 'react-redux'

export default function FiltersProducts(props) {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    return (
        <Container>
            <div className="mx-auto max-w-3xl px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between">
                    <PopoverGroup className="hidden sm:flex sm:items-baseline sm:space-x-8">
                        <Popover
                            key={"section.name"}
                            id={`desktop-menu-${"sectionIdx"}`}
                            className="relative inline-block text-left"
                        >
                            <div>
                                <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                    <span style={{ marginRight: "0px", backgroundColor: "transparent", border: "2px solid transparent", width: '30px', display: "flex", justifyContent: "right" }}>{"Mídias"}</span>
                                    <ChevronDownIcon
                                        aria-hidden="true"
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    />
                                </PopoverButton>
                            </div>

                            <PopoverPanel
                                transition
                                className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <form className="space-y-4">
                                    <div key={"option.value"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.medias}
                                            onChange={() => { props.setMedias(!props.medias) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`Com mídia`}
                                        </label>
                                    </div>
                                    <div key={"option.value0"} className="flex items-center">
                                        <input
                                            defaultValue={"option.value"}
                                            id={`filter-${"section.id"}-${"optionIdx"}`}
                                            name={`${"section.id"}[]`}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            checked={props.noMedias}
                                            onChange={() => { props.setNoMedias(!props.noMedias) }}
                                        />
                                        <label htmlFor={`filter-${"section.id"}-${"optionIdx"}`} className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"                                        >
                                            {`Sem mídia`}
                                        </label>
                                    </div>
                                </form>
                            </PopoverPanel>
                        </Popover>
                    </PopoverGroup>
                </div>
            </div>

            <button
                onClick={() => {
                    //props.setVisibleModalMenuSinc(true) 
                    dispatch({ type: "SAGA_SEARCH_PLATFORM_ALL_PRODUCTS", payload: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform });
                }}
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-green-50 px-3.5 py-2.5 text-sm font-semibold text-green-700 hover:bg-green-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                <ArrowPathIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                Sincronizar
            </button>
        </Container>
    )
}
