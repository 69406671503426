import { ArrowDownTrayIcon, ArrowPathIcon, CodeBracketIcon, EllipsisVerticalIcon, EnvelopeIcon, PaperClipIcon, PhotoIcon, ShoppingBagIcon, StarIcon } from "@heroicons/react/24/outline"
// import { BoxIcon, Submenu } from "../../../../cupons/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { ButtonAction, Message, TextQtd } from "./styled"
import ModalMenuItem from "../../modal-menu-item"
import ModalSelectActions from "../../modal-select-action"
import { SectionAnexos } from "../../card-variante/styled"
import { IconFile } from "../../card-product/styled"

import IconUrl from "../../../../../assets/imgs/icons/code.svg";
import IconDefault from "../../../../../assets/imgs/icons/default.svg"
import IconVideo from "../../../../../assets/imgs/icons/videoplay.svg";
import IconImage from "../../../../../assets/imgs/icons/image.svg";
import IconPdf from "../../../../../assets/imgs/icons/pdf.svg";
import ModalMenuVariant from "../../modal-menu-variants"
import ModalUploadAnexo from "../../modal-upload-anexo"
import ModalConectarAnexo from "../../modal-conectar-anexo"
import ModalVincularAnexo from "../../modal-vincular-anexo"
import ModalConfirmation from "../../../../../components/Modal-Confirmation"
import { translations } from "../../../../../utils/language"
import { useParams } from "react-router-dom"

const ItemTableVariant = (props) => {

    const [visibleModalMenu, setvisibleModalMenu] = useState(false)
    // const [visibleModal, setVisibleModal] = useState(false)
    const [fullMessage, setfullMessage] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const { id } = useParams();

    const language = useSelector((state) => state.reducerLanguage);

    useEffect(() => {
        console.log("ItemTableVariant", props)
    }, [props])

    const [deleteMedia, setDeleteMedia] = useState(null)

    const clickMedia = (itemMedia) => {
        setDeleteMedia(itemMedia)
        setVisibleModalConfirmation(true)
    }

    const deleteMediaProduct = () => {
        console.log("deleteMedia ::", deleteMedia)
        console.log("props.item ::", props.item.variants)
        //alert("teste")
        dispatch({
            type: "DELETE_LINK_MEDIA_PRODUCTS",
            payload: {
                media_id: deleteMedia.id,
                product_id: props.item.variants.product_id,
            },
        });
    }


    const getIconFile = (type, tooltip, media) => {
        switch (type?.toLowerCase()) {
            case "png": case "jpg": case "jpeg": case "gif": case "bmp": case "psd": case "svg": case "WEBP":
                return (
                    <IconFile
                        onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        <img src={IconImage} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "pdf":
                return (
                    <IconFile
                        onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        <img src={IconPdf} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "mp4": case "avi": case "mkv":
                return (
                    <IconFile
                        onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        <img src={IconVideo} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "url":
                return (
                    <IconFile
                        onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <BsLink45Deg color={iconFile.color} size={iconFile.size} /> */}
                        <img src={IconUrl} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )

            default:
                return (
                    <IconFile
                        onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <BsFillFileEarmarkFill color={iconFile.color} size={iconFile.size} /> */}
                        <img src={IconDefault} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
        }
    }

    const [visibleModalUpload, setVisibleModalUpload] = useState(false)
    const [visibleModalConectar, setVisibleModalConectar] = useState(false)
    const [visibleModalVincular, setVisibleModalVincular] = useState(false)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)

    return (
        <>
            <ModalMenuVariant
                //setVisibleModalEdit={setVisibleModal}
                item={props.item}
                visible={visibleModalMenu}
                setVisibleModal={setvisibleModalMenu}
                visibleModalUpload={visibleModalUpload}
                setVisibleModalUpload={setVisibleModalUpload}
                visibleModalConectar={visibleModalConectar}
                setVisibleModalConectar={setVisibleModalConectar}
                visibleModalVincular={visibleModalVincular}
                setVisibleModalVincular={setVisibleModalVincular}
            />

            <ModalUploadAnexo
                //goSearch={props.goSearch}
                //search={props.search}
                //medias={props.item.media}
                getIconFile={getIconFile}
                item={props.item}
                //selectedVariante={selectedVariante}
                visible={visibleModalUpload}
                setVisible={setVisibleModalUpload}
                setVisibleModal={setvisibleModalMenu}
            />
            <ModalConectarAnexo
                //search={props.search}
                //medias={props.item.media}
                getIconFile={getIconFile}
                item={props.item}
                //selectedVariante={selectedVariante}
                visible={visibleModalConectar}
                setVisible={setVisibleModalConectar}
                setVisibleModal={setvisibleModalMenu}
            />
            <ModalVincularAnexo
                //search={props.search}
                //medias={props.item.media}
                getIconFile={getIconFile}
                item={props.item}
                //selectedVariante={selectedVariante}
                visible={visibleModalVincular}
                setVisible={setVisibleModalVincular}
                setVisibleModal={setvisibleModalMenu}
            />

            <ModalConfirmation
                confirmation={deleteMediaProduct}
                setVisibleModal={setVisibleModalConfirmation}
                visible={visibleModalConfirmation}
                //title={"Deseja desvincular essa mídia?"}
                title={`Deseja desvincular a mídia ${deleteMedia?.type == "url" ? deleteMedia?.media : deleteMedia?.media_name} ?`}
            />

            <tr key={"person.email"} className={"even:bg-gray-50"}>

                <td style={{ paddingLeft: "20px" }}
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        'text-gray-900',
                    )}
                >
                    {new Date(props.item.variants.created_at).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", gap: "20px", alignItems: "center", cursor: "initial" }}>
                        <Message style={{ cursor: "initial" }} full={fullMessage}                         >
                            <label style={{ cursor: "initial" }} onClick={() => {
                                //window.open(props.item.products.canonical_url, '_blank')
                            }}>
                                {props.item.variants.name}
                            </label>
                        </Message>
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <SectionAnexos>
                        {props.item.media.map((item) => {
                            return (
                                item.type == "url" ?
                                    getIconFile("url", item.media, item, "getStatusActiveIcon()") :
                                    getIconFile(item.media_name.split(".")[item.media_name.split(".").length - 1], item.media_name, item, "getStatusActiveIcon()")
                            )
                        })}
                    </SectionAnexos>
                </td>

                <td style={{ display: "flex", justifyContent: "flex-end" }} className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    {/* <button type="button" class="inline-flex items-center gap-x-2 rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                        </svg>
                        Anexar
                    </button> */}
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => {
                            setvisibleModalMenu(true)
                        }} style={{ height: "32px", cursor: "pointer" }} />

                    </span>
                </td>

            </tr>
        </>

    )
}

export default ItemTableVariant